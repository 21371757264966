@tailwind base;
@tailwind components;
@tailwind utilities;

.icon {
  font-family: 'FontAwesome'; /* أو المكتبة المستخدمة */
}

body {
  margin: 0;
  font-family: 'Tajawal', sans-serif; /* استخدام خط تجوال */
  direction: rtl; /* تحديد اتجاه النص */
}

.min-h-screen {
  min-height: 100vh;
}

.bg-gradient-to-b {
  /* background: linear-gradient(to bottom, #d1fae5, #99f6e4); */
  background: linear-gradient(to bottom, #528e88, #528e88);
}

.text-emerald-800 {
  color: #065f46;
}

.text-emerald-600 {
  color: #047857;
}

/* أضف المزيد من الأنماط حسب الحاجة */

/* QuranPlayer.css */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

input[type="range"]::-webkit-slider-thumb {
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background-color: #059669;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  background-color: #059669;
  cursor: pointer;
}

/* في ملف CSS عام */
::-webkit-scrollbar {
  display: none;
}


.Toastify__close-button {
  display: none; /* إخفاء زر الإغلاق */
}

.Toastify__toast {
  font-family: 'Tajawal', sans-serif; /* استبدل 'اسم الخط هنا' باسم الخط الذي تستخدمه */
  font-size: 16px; /* يمكنك تغيير حجم الخط حسب الحاجة */
  color: #333; /* يمكنك تغيير لون النص حسب الحاجة */
}

.scrollable::-webkit-scrollbar {
  display: none; /* لإخفاء شريط التمرير في Chrome و Safari */
}

/* عند تطبيق التأثير */
.transition-all {
  transition-property: all; /* تطبيق التأثير على جميع الخصائص */
  transition-timing-function: ease-in-out; /* تحديد توقيت الحركة */
  transition-duration: 0.4s; /* تحديد مدة التأثير */
}

/* تنسيق التنبيهات في الوضع الليلي */
.dark-toast {
  font-family: 'Tajawal', sans-serif !important;
}

.dark-toast .Toastify__toast {
  background-color: #1f2937 !important;
  color: #e5e7eb !important;
  border: 1px solid #374151;
}

.dark-toast .Toastify__close-button {
  color: #e5e7eb !important;
}

.dark-toast .Toastify__progress-bar {
  background: #10b981 !important;
}

/* تنسيق التنبيهات في الوضع العادي */
.light-toast {
  font-family: 'Tajawal', sans-serif !important;
}

.light-toast .Toastify__toast {
  background-color: #ffffff !important;
  color: #1f2937 !important;
  border: 1px solid #e5e7eb;
}

.light-toast .Toastify__close-button {
  color: #1f2937 !important;
}

.light-toast .Toastify__progress-bar {
  background: #10b981 !important;
}

/* القاعدة الأساسية لجميع المتصفحات */
.surah-number {
  color: #047857;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  transform: rotate(-45deg);
  font-weight: var(--font-weight-bold);
  line-height: normal;
}

/* قاعدة خاصة لمتصفح Safari */
@supports (-webkit-hyphens:none) {
  .surah-number {
    padding-top: 5px;
  }
}

/* قواعد خاصة لمتصفح Safari */
@supports (-webkit-touch-callout: none) {
  .flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
  }
  
  .items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}

/* قواعد خاصة لمحاذاة الأرقام في iOS */
@supports (-webkit-touch-callout: none) {
  .number-circle {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-bottom: 0rem !important; /* لتعديل موضع الرقم للأعلى قليلاً */
    line-height: 1 !important;
  }
  
  .number-circle span {
    transform: translateY(3px) !important;
    display: block !important;
  }
}

/* قواعد خاصة لمحاذاة أيقونات البحث */
.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  width: 100%;
  height: 3rem;
  padding: 0.75rem 2.5rem;
  border-radius: 0.75rem;
  outline: none;
  transition: all 0.3s ease;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.5rem;
}

.search-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* تعديل محاذاة حقل البحث للسفاري */
@supports (-webkit-touch-callout: none) {
  .search-input {
    height: 2.5rem;
    line-height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .search-icon {
    height: 2.5rem;
    display: inline-flex;
    align-items: center;
  }
}

/* تنسيق عرض حواف البطاقات في صفحة الأذكار */
.athkar-container {
  padding: 0;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.athkar-cards {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  gap: 0;
  padding: 1rem 2rem;
  margin: 0 -2rem;
  width: 100%;
}

.athkar-card {
  scroll-snap-align: center;
  flex: 0 0 85%;
  margin-right: -2rem; /* يظهر جزء من البطاقة التالية */
  position: relative;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.athkar-card:last-child {
  margin-right: 2rem;
}

/* إظهار حواف البطاقات المجاورة */
.athkar-cards::before,
.athkar-cards::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3rem;
  pointer-events: none;
  z-index: 2;
}

.athkar-cards::before {
  right: 0;
  background: linear-gradient(to left, rgba(255,255,255,0.95), transparent);
}

.athkar-cards::after {
  left: 0;
  background: linear-gradient(to right, rgba(255,255,255,0.95), transparent);
}

/* تنسيق بطاقات التسبيح */
.tasbih-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0 1rem;
}

.tasbih-cards {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  gap: 1rem;
  padding: 1rem;
  margin: 0 -1rem;
}

.tasbih-card {
  scroll-snap-align: center;
  flex: 0 0 90%;
  margin-right: -3rem; /* يظهر جزء من البطاقة التالية */
  border: 2px solid #ef4444;
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
  background: white;
}

.tasbih-card:last-child {
  margin-right: 3rem;
}

/* إظهار حواف البطاقات المجاورة */
.tasbih-cards::before,
.tasbih-cards::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4rem;
  pointer-events: none;
  z-index: 2;
}

.tasbih-cards::before {
  right: 0;
  background: linear-gradient(to left, rgba(255,255,255,0.95), transparent);
}

.tasbih-cards::after {
  left: 0;
  background: linear-gradient(to right, rgba(255,255,255,0.95), transparent);
}
