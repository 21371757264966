.swiper {
  width: 100%;
  padding: 20px 0;
}

.swiper-slide {
  width: 100%;
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.swiper-slide > div {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

/* تأثير الضباب للبطاقات غير النشطة */
.swiper-slide:not(.swiper-slide-active) > div {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.3;
}

.dark .swiper-slide:not(.swiper-slide-active) > div {
  background-color: rgba(31, 41, 55, 0.5);
}

.dark .swiper-slide > div {
  border-color: rgba(255, 255, 255, 0.1);
}

