/* تنسيقات Bottom Sheet */
.bottom-sheet-animation {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: #e2e8f0;
  --rsbs-max-w: 100%;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: 16px;
  --rsbs-overlay-h: 0px;
  --rsbs-overlay-pt: env(safe-area-inset-top);
  --rsbs-overlay-pb: env(safe-area-inset-bottom);
  --rsbs-antigap-scale-y: 0;
  --rsbs-overlay-translate-y: 0px;
  --rsbs-overlay-transform: translateY(var(--rsbs-overlay-translate-y));
}

.bottom-sheet-animation[data-rsbs-state='open'] {
  --rsbs-overlay-translate-y: 0px;
  --rsbs-overlay-h: var(--rsbs-window-h);
  --rsbs-antigap-scale-y: 0;
}

.bottom-sheet-animation[data-rsbs-state='after-open'] {
  --rsbs-overlay-translate-y: 0px;
  --rsbs-overlay-h: var(--rsbs-window-h);
  --rsbs-antigap-scale-y: 0;
}

.bottom-sheet-animation[data-rsbs-state='closing'] {
  --rsbs-overlay-translate-y: 0px;
  --rsbs-overlay-h: var(--rsbs-window-h);
  --rsbs-antigap-scale-y: 0;
}

.bottom-sheet-animation[data-rsbs-state='closed'] {
  --rsbs-overlay-translate-y: 0px;
  --rsbs-overlay-h: 0px;
  --rsbs-antigap-scale-y: 0;
}

/* أنماط النافذة المنبثقة - تبسيط القواعد */
.custom-bottom-sheet {
  border-radius: 35px 35px 35px 35px; /* زيادة انحناء الزوايا السفلية */
  overflow: hidden;
  touch-action: none; /* منع جميع الإجراءات الافتراضية للمس */
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  
  /* تأثير زجاجي */
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.18);
  
  /* تحسين الرسوم المتحركة */
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dark.custom-bottom-sheet {
  background-color: rgba(31, 41, 55, 0.8);
  border: 1px solid rgba(75, 85, 99, 0.18);
}

/* أنماط النافذة العائمة */
.floating-sheet {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  transform-origin: center bottom;
  will-change: transform, opacity;
}

.floating-sheet.expanded {
  transform: scale(1.02);
}

/* تحسين الرسوم المتحركة للظهور الأول */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.first-render-animation {
  animation: slideUp 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

/* مقبض السحب */
.drag-handle {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  touch-action: none;
  user-select: none;
  position: relative;
  z-index: 1;
  transition: background-color 0.2s ease;
}

.drag-handle:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dark .drag-handle:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.drag-handle:active {
  cursor: grabbing;
  background-color: rgba(0, 0, 0, 0.05);
}

.dark .drag-handle:active {
  background-color: rgba(255, 255, 255, 0.08);
}

/* مؤشر السحب */
.drag-indicator {
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: #e2e8f0;
  pointer-events: none;
  transition: width 0.2s ease, background-color 0.2s ease;
}

.drag-handle:hover .drag-indicator {
  width: 50px;
}

.dark .drag-indicator {
  background-color: #4b5563;
}

/* تأثير الارتداد - تبسيط */
@keyframes bounce {
  0% { transform: translateY(0); }
  40% { transform: translateY(8px); }
  70% { transform: translateY(4px); }
  100% { transform: translateY(0); }
}

.bounce-effect {
  animation: bounce 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* أنماط شريط التمرير المخصص */
.custom-bottom-sheet-content {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  touch-action: pan-y; /* السماح بالتمرير العمودي داخل المحتوى فقط */
  max-height: calc(70vh - 24px);
  overflow-y: auto;
  padding: 0 16px 16px;
}

/* تحسين تفاعل الأزرار */
.custom-bottom-sheet-content button,
.custom-bottom-sheet-content a,
.custom-bottom-sheet-content input,
.custom-bottom-sheet-content .player-content {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.custom-bottom-sheet-content::-webkit-scrollbar {
  width: 6px;
}

.custom-bottom-sheet-content::-webkit-scrollbar-track {
  background: transparent;
}

.custom-bottom-sheet-content::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

.custom-bottom-sheet-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.8);
}

.dark .custom-bottom-sheet-content::-webkit-scrollbar-thumb {
  background-color: rgba(75, 85, 99, 0.5);
}

.dark .custom-bottom-sheet-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(75, 85, 99, 0.8);
}

/* تخصيص الوضع المظلم */
.dark [data-rsbs-overlay] {
  --rsbs-bg: #1f2937;
  --rsbs-handle-bg: #4b5563;
}

/* تأثير الظل للنافذة العائمة */
.floating-sheet {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.dark .floating-sheet {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

/* تحسين مظهر المحتوى في النافذة العائمة */
.floating-sheet .custom-bottom-sheet-content {
  padding: 0 16px 16px;
  border-radius: 0 0 35px 35px; /* زيادة انحناء المحتوى من الأسفل فقط */
}

/* تحسين مقبض السحب */
.floating-sheet .drag-handle {
  position: relative;
  overflow: hidden;
}

/* تعطيل التأثيرات البصرية للنقر على الأجهزة المحمولة */
.custom-bottom-sheet input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
}

/* تأثير زجاجي للنافذة العائمة */
@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .floating-sheet {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  
  .dark.floating-sheet {
    background-color: rgba(31, 41, 55, 0.8);
    border: 1px solid rgba(75, 85, 99, 0.18);
  }
}

/* دعم المتصفحات التي لا تدعم تأثير الضبابية */
@supports not ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  .floating-sheet {
    background-color: rgba(255, 255, 255, 0.95);
  }
  
  .dark.floating-sheet {
    background-color: rgba(31, 41, 55, 0.95);
  }
}

/* تحسين تجربة السحب بالماوس */
.custom-bottom-sheet * {
  cursor: inherit;
}

/* تغيير مؤشر الماوس عند التحويم فوق مقبض السحب */
.drag-handle {
  cursor: grab !important;
}

.drag-handle:active {
  cursor: grabbing !important;
} 