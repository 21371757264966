/* إخفاء المسار الافتراضي في Firefox */
input[type="range"]::-moz-range-track {
  background: transparent;
}

.icon {
  font-family: 'FontAwesome'; /* أو المكتبة المستخدمة */
}

/* تخصيص شكل المسار */
input[type="range"] {
  -webkit-appearance: none; /* إزالة المظهر الافتراضي للمتصفح */
  appearance: none;
  width: 100%;
  height: 6px; /* ارتفاع الشريط */
  background: #e5e7eb; /* لون الخلفية الافتراضي للمسار */
  border-radius: 10px; /* حواف مستديرة */
  outline: none;
  position: relative;
}

/* الخلفية المتدرجة للمسار */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: linear-gradient(to left, #10b981 var(--progress, 0%), #e5e7eb var(--progress, 0%)); /* تدرج اللون حسب التقدم */
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

/* تخصيص نقطة التحكم (Thumb) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px; /* عرض النقطة */
  height: 16px; /* ارتفاع النقطة */
  background: #10b981; /* نفس لون الشريط المتقدم */
  cursor: pointer;
  border-radius: 50%; /* شكل دائري */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* ظل خفيف */
  margin-top: -5px; /* تصحيح موقع النقطة */
  position: absolute;
  right: calc(var(--progress, 0%) - 8px); /* تعديل الموقع بنصف عرض النقطة */
  max-width: 100%; /* منع التمدد خارج الشريط */
  z-index: 10;
  transition: none !important;
}

/* تخصيص نقطة التحكم في Firefox */
input[type="range"]::-moz-range-thumb {
  width: 16px; /* عرض النقطة */
  height: 16px; /* ارتفاع النقطة */
  background: #10b981; /* نفس لون الشريط المتقدم */
  cursor: pointer;
  border-radius: 50%; /* شكل دائري */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* ظل خفيف */
  border: none;
}

/* تأثير التحويم على النقطة */
input[type="range"]::-webkit-slider-thumb:hover {
  transform: scale(1.1); /* زيادة الحجم قليلاً */
  background: #059669; /* لون مختلف عند التحويم */
}

input[type="range"]::-moz-range-thumb:hover {
  background: #059669; /* لون مختلف عند التحويم */
  transform: scale(1.2); /* زيادة الحجم قليلاً */
}

/* تخصيص قائمة السور */
.surah-list {
  display: grid;
  gap: 1.5rem;
}

.surah-item {
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  background-color: white;
  transition: all 0.2s ease;
}

.surah-item:last-child {
  margin-bottom: 0;
}

.surah-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* تخصيص محتوى المشغل */
.player-content {
  background-color: transparent;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  transition: all 0.3s ease;
}

/* تنسيق حاوية أزرار التحكم */
.audio-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

/* تنسيق الأزرار */
.control-btn {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #10b981;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
}

/* زر التشغيل/الإيقاف */
.control-btn.play-btn {
  width: 3rem;
  height: 3rem;
}

/* زر السرعة */
.control-btn.speed-btn {
  font-weight: bold;
  width: auto;
  padding: 0 1rem;
  border-radius: 1.25rem;
}

/* زر التكرار عند التفعيل */
.control-btn.active-btn {
  background-color: #059669;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #059669;
}

/* تأثيرات التحويم */
.control-btn:hover:not(:disabled) {
  background-color: #059669;
  transform: scale(1.05);
}

/* تأثير النقر */
.control-btn:active:not(:disabled) {
  transform: scale(0.95);
}

/* تعطيل الزر */
.control-btn:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  opacity: 0.7;
}

/* حجم الأيقونات */
.control-btn i {
  font-size: 1rem;
}

.control-btn.play-btn i {
  font-size: 1.25rem;
}

.quran-player {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  direction: rtl;
}

.player-header {
  margin-bottom: 20px;
  text-align: center;
}

.player-header h1 {
  font-size: 2rem;
  color: #065f46;
  margin-bottom: 1rem;
}

.reciter-selection {
  margin-bottom: 1rem;
}

.reciter-selection label {
  margin-left: 10px;
  color: #065f46;
}

.reciter-select {
  padding: 8px 16px;
  border: 1px solid #10b981;
  border-radius: 8px;
  background-color: white;
  color: #065f46;
  font-size: 1rem;
  cursor: pointer;
  min-width: 200px;
}

.search-box {
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 8px 16px;
  border: 1px solid #10b981;
  border-radius: 8px;
  font-size: 1rem;
  color: #065f46;
}

.search-input::placeholder {
  color: #6ee7b7;
}

/* قائمة السور مع التمرير */
.surahs-container {
  padding: 0;
  margin-bottom: 0;
  position: relative;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.surahs-list {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 12px;
}

.surah-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 16px;
}

.surah-item:hover {
  background-color: #f0fdf4;
}

.surah-item.active {
  background-color: #dcfce7;
}

.surah-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}


.surah-name {
  font-size: 1.1rem;
  color: #065f46;
  font-weight: 500;
}

.verses-count {
  color: #059669;
  font-size: 0.9rem;
}

.surah-controls button {
  background-color: #10b981;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.surah-controls button:hover {
  background-color: #059669;
}

/* تنسيق شريط التمرير */
.surahs-list::-webkit-scrollbar {
  width: 8px;
}

.surahs-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.surahs-list::-webkit-scrollbar-thumb {
  background: #10b981;
  border-radius: 4px;
}

.surahs-list::-webkit-scrollbar-thumb:hover {
  background: #059669;
}

.background-nub {
  background-color: #d1fae5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(2.2* var(--spacing-medium));
  height: calc(2.2* var(--spacing-medium));
  border-radius: var(--border-radius-default);
  transform: rotate(45deg);
  margin-right: 5px;
}

.surah-number {
  color: #047857;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  transform: rotate(-45deg);
  font-weight: var(--font-weight-bold);
  line-height: normal;
}

:root {
    --spacing-micro: 0.1875rem;
    --spacing-xxsmall: 0.375rem;
    --spacing-xsmall: 0.625rem;
    --spacing-small: 0.8125rem;
    --spacing-medium: 1rem;
    --spacing-large: 1.1875rem;
    --spacing-mega: 2rem;
    --spacing-micro-px: 2px;
    --spacing-xxsmall-px: 5px;
    --spacing-xsmall-px: 8px;
    --spacing-small-px: 10px;
    --spacing-medium-px: 15px;
    --spacing-medium2-px: 20px;
    --spacing-large-px: 30px;
    --spacing-xlarge-px: 40px;
    --spacing-xxlarge-px: 50px;
    --spacing-mega-px: 100px;
    --font-size-xsmall-dangerous: 0.6rem;
    --font-size-xsmall: 0.75rem;
    --font-size-small: 0.875rem;
    --font-size-normal: 1rem;
    --font-size-large: 1.125rem;
    --font-size-xlarge: 1.5rem;
    --font-size-jumbo: 2rem;
    --line-height-small: 0.8125rem;
    --line-height-normal: 1rem;
    --line-height-large: 1.1875rem;
    --line-height-xlarge: 1.375rem;
    --line-height-jumbo: 2.25rem;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --border-radius-sharp: 0;
    --border-radius-pill: 20rem;
    --border-radius-default: 0.25rem;
    --border-radius-rounded: 0.5rem;
    --border-radius-circle: 50%;
    --opacity-10: 10%;
    --opacity-30: 30%;
    --opacity-50: 50%;
    --opacity-75: 75%;
    --opacity-85: 85%;
    --z-index-min: -999;
    --z-index-default: 1;
    --z-index-sticky: 300;
    --z-index-header: 400;
    --z-index-overlay: 500;
    --z-index-dropdown: 600;
    --z-index-spinner: 700;
    --z-index-modal: 800;
    --z-index-onboarding-overlay: 801;
    --z-index-onboarding-content: 802;
    --z-index-onboarding-step: 803;
    --z-index-onboarding-spotlight: 804;
    --z-index-max: 999;
    --transition-fast: 0.16s;
    --transition-regular: 0.4s;
    --transition-slow: 0.6s;
    --shade-0: #f8f9fa;
    --shade-1: #f4f5f6;
    --shade-2: #e9ecef;
    --shade-3: #dee2e6;
    --shade-4: #ced4da;
    --shade-5: #adb5bd;
    --shade-6: #868e96;
    --shade-7: #495057;
    --shade-8: #343a40;
    --shade-9: #212529;
    --color-primary-faded: #fff;
    --color-primary-faint: #fff;
    --color-primary-medium: #000;
    --color-primary-deep: #fff;
    --color-secondary-faded: #eaeaea;
    --color-secondary-faint: #999;
    --color-secondary-medium: #f2f2f2;
    --color-secondary-deep: #333;
    --color-success-faded: #ebf9fa;
    --color-success-faint: #c2edef;
    --color-success-medium: #2ca4ab;
    --color-success-deep: #258c91;
    --color-error-faded: #f7d4d6;
    --color-error-faint: #ff1a1a;
    --color-error-medium: #ee0102;
    --color-error-deep: #c50000;
    --color-warning-faded: #fef3dc;
    --color-warning-faint: #ffdfaa;
    --color-warning-medium: #ffb300;
    --color-warning-deep: #ab570a;
    --color-text-default: #272727;
    --color-text-faded: #666;
    --color-text-inverse: #fff;
    --color-text-link: #2ca4ab;
    --color-text-warning: #ab570a;
    --color-text-error: #c50000;
    --color-background-default: #fff;
    --color-background-elevated: #fff;
    --color-background-alternative-faded: var(--shade-0);
    --color-background-alternative-faint: var(--shade-1);
    --color-background-alternative-medium: var(--shade-2);
    --color-background-alternative-deep: var(--shade-6);
    --color-background-inverse: #000;
    --color-background-backdrop: rgba(0,0,0,.6);
    --color-background-lighten: hsla(0,0%,100%,.8);
    --color-borders-hairline: #ebeef0;
    --color-highlight: #79ffe1;
    --shadow-small: 0px 2px 4px rgba(0,0,0,.1);
    --shadow-normal: 0px 4px 8px rgba(0,0,0,.12);
    --shadow-large: 0 5px 10px rgba(0,0,0,.12);
    --shadow-xlarge: 0 8px 30px rgba(0,0,0,.12);
    --shadow-jumbo: 0 30px 60px rgba(0,0,0,.12);
    --shadow-hover: 0 30px 60px rgba(0,0,0,.12);
    --shadow-sticky: 0 12px 10px -10px rgba(0,0,0,.12);
    font-palette: --Light;
    --scrollbar-background: var(--shade-2);
    --scrollbar-thumb: var(--shade-4);
    --scrollbar-thumb-hover: var(--shade-6);
    --scrollbar-thumb-active: var(--shade-7)
}


.surah-search {
  margin-bottom: 1rem;
}

/* تخصيص مكون BottomSheet */
[data-rsbs-overlay] {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  background-color: #fff !important;
  box-shadow: 0 -5px 60px 0 rgba(0, 0, 0, 0.25) !important;
}

.dark [data-rsbs-overlay] {
  background-color: #1f2937 !important;
}

[data-rsbs-header] {
  padding-top: 16px !important;
  padding-bottom: 8px !important;
}

[data-rsbs-header]:before {
  width: 36px !important;
  height: 4px !important;
  background-color: #e2e8f0 !important;
  border-radius: 2px !important;
}

.dark [data-rsbs-header]:before {
  background-color: #4b5563 !important;
}

[data-rsbs-backdrop] {
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(3px) saturate(80%) !important;
  -webkit-backdrop-filter: blur(3px) saturate(80%) !important;
}

/* تحسين الأداء */
[data-rsbs-overlay],
[data-rsbs-backdrop] {
  will-change: transform, opacity !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}
